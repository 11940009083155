<script>
import Paginate from '@/components/Pagination/index.vue';
import ProgressLine from "@/views/bonusSystem/components/progress/ProgressLine.vue";
import {mapActions, mapState} from "vuex";
import MissionCard from "@/views/bonusSystem/components/MissionCard.vue";
import MissionPagination from "@/views/bonusSystem/components/MissionPagination.vue";
import displaySettings from "@/mixin/displaySettings";

export default {
    name: "ListMissions",
    components: {MissionPagination, MissionCard, ProgressLine, Paginate},
    mixins: [displaySettings],
    data() {
        return {
            activeName: 'all',
            tabOptions: [
                {
                    value: 'all',
                    label: 'Всі'
                },
                {
                    value: 'done',
                    label: 'Виконані'
                },
                {
                    value: 'in-progress',
                    label: 'В процесі'
                },
            ]
        };
    },
    computed: {
        ...mapState('missionsStore', [
            'missions',
            'paginate',
            'loading'
        ]),
    },
    methods: {
        ...mapActions('missionsStore', [
            'getMissions'
        ]),
        handleClick(tab, event) {
            this.getMissions({tab: tab.name});
        },
        handleChange() {
            this.getMissions({tab: this.activeName})
        }
    },
    mounted() {
        this.getMissions();
    }
}
</script>

<template>
    <div class="mission-list">
        <div v-if="isMobile" class="pt-3">
            <el-row>
                <el-col>
                    <el-select v-model="activeName" @change="handleChange" placeholder="Select" class="w-100">
                        <el-option
                            v-for="item in tabOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
            </el-row>
            <div v-if="loading">
                <el-row :key="item" v-for="item in 5">
                    <el-card>
                        <PuSkeleton
                            height="100px"
                            class="skeleton-input"
                        ></PuSkeleton>
                    </el-card>
                </el-row>
            </div>
            <div v-else>
                <el-row :key="key" v-for="(item, key) in missions">
                    <mission-card :item="item"></mission-card>
                </el-row>
            </div>
            <mission-pagination class="mt-3"></mission-pagination>
        </div>
        <div v-else>
            <el-tabs v-model="activeName" @tab-click="handleClick" class="tabs">
                <el-tab-pane label="Всі" name="all" class="pt-4">
                    <div v-if="loading">
                        <el-row :key="item" v-for="item in 5">
                            <el-card>
                                <PuSkeleton
                                    height="100px"
                                    class="skeleton-input"
                                ></PuSkeleton>
                            </el-card>
                        </el-row>
                    </div>
                    <div v-else>
                        <el-row :key="key" v-for="(item, key) in missions">
                            <mission-card :item="item"></mission-card>
                        </el-row>
                    </div>
                    <mission-pagination class="mt-5"></mission-pagination>
                </el-tab-pane>
                <el-tab-pane label="Виконані" name="done" class="pt-4">
                    <div v-if="loading">
                        <el-row :key="item" v-for="item in 5">
                            <el-card>
                                <PuSkeleton
                                    height="100px"
                                    class="skeleton-input"
                                ></PuSkeleton>
                            </el-card>
                        </el-row>
                    </div>
                    <div v-else>
                        <el-row :key="key" v-for="(item, key) in missions">
                            <mission-card :item="item"></mission-card>
                        </el-row>
                    </div>
                    <mission-pagination class="mt-5"></mission-pagination>
                </el-tab-pane>
                <el-tab-pane label="В процесі" name="in-progress" class="pt-4">
                    <div v-if="loading">
                        <el-row :key="item" v-for="item in 5">
                            <el-card>
                                <PuSkeleton
                                    height="100px"
                                    class="skeleton-input"
                                ></PuSkeleton>
                            </el-card>
                        </el-row>
                    </div>
                    <div v-else>
                        <el-row :key="key" v-for="(item, key) in missions">
                            <mission-card :item="item"></mission-card>
                        </el-row>
                    </div>
                    <mission-pagination class="mt-5"></mission-pagination>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<style scoped>
* >>> .el-select {
    .el-input.is-focus {
        border-color: #FFAD3D;
    }
    .el-input__inner:focus {
        border-color: #FFAD3D;
    }
}
</style>

<style>
body {
    .el-select-dropdown{
        @media (max-width: 768px) {
            width: auto !important;
        }
    }
}
</style>
