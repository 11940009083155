<script>
import ProgressCircle from "@/views/bonusSystem/components/progress/ProgressCircle.vue";
import {mapActions} from "vuex";
import displaySettings from "@/mixin/displaySettings";

import Swiper from 'swiper/swiper-bundle.min';
import 'swiper/swiper-bundle.min.css';
import CarouselSlideLevel from "@/views/bonusSystem/components/carousel/CarouselSlideLevel.vue";

export default {
    name: "CarouselLevels",
    components: {
        CarouselSlideLevel,
        ProgressCircle,
    },
    mixins: [displaySettings],
    computed: {
        levels() {
            return this.$store.state.levelsStore.levels;
        },
        loading() {
            return this.$store.state.levelsStore.loading
        },
        currentLevelId(){
            return this.$store.getters.USER_INFO.levelId;
        }
    },
    watch: {
        loading: function (value) {
            if (!value) {
                this.$refs.swiper.swiper.destroy()
                this.$nextTick(() => {
                    this.initSwiper()
                })
            }
        }
    },
    methods: {
        ...mapActions('levelsStore', [
            'getLevels'
        ]),
        initSwiper() {
            new Swiper(this.$refs.swiper, {
                slidesPerView: 'auto',
                // initialSlide: this.$store.getters.USER_INFO.levelId,
                // loop: true,
                initialSlide: this.levels.findIndex(item => item.id === this.currentLevelId),
                spaceBetween: 70,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    // enabled: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }
    },
    created() {
        this.getLevels();
    },
    mounted() {
       this.initSwiper()
    },
}
</script>

<template>
    <div style="width: 100%">
        <div class="position-relative">
            <div ref="swiper" class="swiper" :class="isMobile ? '' : 'p-4'">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-if="loading" v-for="item in 4">
                        <PuSkeleton height="256px" class="skeleton-input"></PuSkeleton>
                    </div>
                    <CarouselSlideLevel :key="item.id" :item="item" v-for="item in levels"></CarouselSlideLevel>
                </div>
                <!-- If we need pagination -->
                <div class="swiper-pagination"></div>
                <!-- If we need navigation buttons -->
                <div class="swiper-button-prev" v-if="!isMobile"></div>
                <div class="swiper-button-next" v-if="!isMobile"></div>
            </div>
        </div>
    </div>

    <!-- Slider main container -->
</template>

<style scoped>

.swiper {
    overflow-x: hidden;
    @media (max-width: 992px){
        overflow-x: visible;
    }
    border-radius: 5px;

    .swiper-wrapper {
        border-radius: 5px;
        border-color: #ED79041A;
        width: 100%;
        .swiper-slide {
            border-radius: 5px;
        }
    }

    .swiper-pagination {
        position: relative;

        .swiper-pagination-bullet-active {
            background-color: #FA9B13 !important;
            opacity: unset;
        }

        .swiper-pagination-bullet {
            border-radius: 50%;
            width: 12px;
            height: 12px;
            background-color: #999DA6;
            opacity: unset;
            margin: 12px 4px;
        }
    }

    .swiper-button-prev {
        left: -5px;
        top: 160px;
        color: #000000;

        &::after {
            font-size: 30px;
        }
    }

    .swiper-button-next {
        right: -5px;
        top: 160px;

        color: #000000;

        &::after {
            font-size: 30px;
        }
    }

    .pu-skeleton {
        line-height: 1.5;
    }
}
</style>
