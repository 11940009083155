<script>
import displaySettings from "@/mixin/displaySettings";

export default {
    name: "ProgressLine",
    props: ['value'],
    mixins: [displaySettings],
    computed: {
        percentage() {
            return this.value >= 100 ? 100 : this.value ? Math.round(this.value) : 0
        }
    }
}
</script>

<template>
    <div class="mission-progress text-center">
<!--        <div v-if="isMobile" class="d-flex justify-content-between align-items-center">-->
        <div class="d-flex justify-content-between align-items-center">
            <b-progress class="w-100">
                <b-progress-bar :value="percentage"></b-progress-bar>
            </b-progress>
            <span class="progress-value ml-2">{{ percentage }}%</span>
        </div>
<!--        <div v-else>-->
<!--            <span class="progress-value">0%</span>-->
<!--            <div class="position-relative">-->
<!--                <div class="mission-bonus-value">-->
<!--                    <img src="~@/assets/images/icons/ukasko-bonus.svg"-->
<!--                         width="18"-->
<!--                         height="18"-->
<!--                    >-->
<!--                    <span>500</span>-->
<!--                </div>-->
<!--                <b-progress height="2rem" class="w-100">-->
<!--                    <b-progress-bar :value="0"></b-progress-bar>-->
<!--                </b-progress>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<style scoped>
.mission-progress {
    .progress {
        height: 10px;
        background-color: rgba(255, 173, 61, 0.2);
    }
    .progress-value {
        color: #FFAD3D;
        font-weight: 700;
        font-family: "Circe", sans-serif;
        font-size: 12px;
    }
    .progress-bar {
        background-color: #FFAD3D;
    }
    .mission-bonus-value {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 50%;
        height: 60%;
        span {
            color: #24282C;
            font-weight: 700;
            font-family: "Circe", sans-serif;
        }
    }
}

</style>
