<script>
import {mapActions, mapState} from "vuex";
import displaySettings from "@/mixin/displaySettings";

export default {
    name: "MissionPagination",
    mixins: [displaySettings],
    computed: {
        ...mapState('missionsStore', [
            'paginate',
            'loading'
        ]),
    },
    methods: {
        ...mapActions('missionsStore', [
            'getMissions'
        ]),
    }
}
</script>

<template>
<div class="pagination">
    <el-row v-if="isMobile">
        <el-col>
            <div class="page-size-text text-center">
                Кількість на сторінці:
                <el-dropdown trigger="click">
                            <span class="page-size-value">
                                5 <i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>5</el-dropdown-item>
                        <el-dropdown-item>10</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-col>
        <el-col>
            <el-pagination
                background
                :pager-count="5"
                layout="prev, pager, next"
                :page-size="paginate.perPage"
                :total="paginate.total"
                :current-page="paginate.page"
                @current-change="getMissions({page:$event})"
            >
            </el-pagination>
        </el-col>
    </el-row>
    <el-row v-else type="flex" align="middle">
        <el-col>
            <el-pagination
                background
                layout="prev, pager, next"
                :page-size="paginate.perPage"
                :total="paginate.total"
                :current-page="paginate.page"
                @current-change="getMissions({page:$event})"
            >
            </el-pagination>
        </el-col>
        <el-col>
            <div class="page-size-text">
                Кількість на сторінці:
                <el-dropdown trigger="click">
                            <span class="page-size-value">
                                5 <i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>5</el-dropdown-item>
                        <el-dropdown-item>10</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-col>
    </el-row>
</div>
</template>

<style scoped>

</style>
