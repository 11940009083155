<script>
import CarouselLevels from "@/views/bonusSystem/components/carousel/CarouselLevels.vue";
import Statistics from "@/views/bonusSystem/components/Statistics.vue";
import MissionsList from "@/views/bonusSystem/components/MissionList.vue";
import displaySettings from "@/mixin/displaySettings"

export default {
    name: "index",
    components: {MissionsList, Statistics, CarouselLevels},
    mixins: [displaySettings],
}
</script>

<template>
<div class="container bonus-system" :class="isMobile ? 'p-0' : ''">
    <el-row v-if="isMobile">
        <el-tabs class="tabs" value="general-info">
            <el-tab-pane label="Загальна інформація" name="general-info">
                <carousel-levels></carousel-levels>
                <statistics></statistics>
            </el-tab-pane>
            <el-tab-pane label="Мої завдання" name="my-tasks">
                <missions-list></missions-list>
            </el-tab-pane>
        </el-tabs>
    </el-row>
    <el-row v-else :gutter="80">
        <el-col :span="12" class="p-0">
            <carousel-levels></carousel-levels>
            <statistics class="p-4"></statistics>
        </el-col>
        <el-col :span="12">
            <missions-list></missions-list>
        </el-col>
    </el-row>
</div>
</template>

<style scoped>

</style>
