<script>
export default {
    name: "MissionIconType",
    props: ['type']
}
</script>

<template>
<div class="d-block">
    <div class="circle text-center">
        <img v-if="type === 'open-safe-box-with-piggy-bank'" width="50.08" height="28.17" src="~@/assets/images/bonus-system/open-safe-box-with-piggy-bank.png" alt="">
        <img v-else-if="type === 'commodity'" width="45" height="45" src="~@/assets/images/bonus-system/commodity.png" alt="">
        <img v-else-if="type === 'time-management'" width="45" height="38.36" src="~@/assets/images/bonus-system/time-management.png" alt="">
    </div>
</div>
</template>

<style scoped>
.circle {
    display: table-cell;
    vertical-align: middle;
    width: 56.34px;
    height: 56.34px;
    background: rgba(255, 173, 61, 0.3);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
}
</style>
