<script>
import displaySettings from "@/mixin/displaySettings";

export default {
    name: "ProgressLineCheckpoints",
    props: ['checkpoints'],
    data() {
        return {
            productTypes:[
                {
                    type_id: 1,
                    textClass: 'text-osago',
                    progressClass: 'progress-osago',
                    progressBarClass: 'progress-bar-osago',
                },
                {
                    type_id: 3,
                    textClass: 'text-green-card',
                    progressClass: 'progress-green-card',
                    progressBarClass: 'progress-bar-green-card',
                },
                {
                    type_id: 5,
                    textClass: 'text-tourism',
                    progressClass: 'progress-tourism',
                    progressBarClass: 'progress-bar-tourism',
                },
            ],
        }
    },
    mixins: [displaySettings]
}
</script>

<template>
    <div class="mission-progress text-center">
        <div class="checkpoints d-flex">
            <div v-for="(item, key) in checkpoints" class="w-100 d-flex justify-content-between align-items-center pt-2 pb-3">
                <div class="flex-fill">
                    <b-progress v-if="item.checkpoint_progress.length > 0 && item.checkpoint_progress[0].percentage && key === 0"
                                class="w-100"
                                :class="productTypes.find(type => type.type_id === item.product_type_id).progressClass"
                    >
                        <b-progress-bar :value="item.checkpoint_progress[0].percentage"
                                        :class="productTypes.find(type => type.type_id === item.product_type_id).progressBarClass"
                        ></b-progress-bar>
                    </b-progress>
                    <b-progress v-else-if="item.checkpoint_progress.length > 0 && item.checkpoint_progress[0].percentage  && checkpoints[key - 1].checkpoint_progress[0].percentage >= 100"
                                class="w-100"
                                :class="productTypes.find(type => type.type_id === item.product_type_id).progressClass"
                    >
                        <b-progress-bar :value="item.checkpoint_progress[0].percentage"
                                        :class="productTypes.find(type => type.type_id === item.product_type_id).progressBarClass"
                        ></b-progress-bar>
                    </b-progress>
                    <b-progress v-else class="w-100">
                        <b-progress-bar :value="0"></b-progress-bar>
                    </b-progress>
                </div>
                <div class="d-block">
                    <div v-if="item.checkpoint_progress.length > 0 && item.checkpoint_progress[0].percentage >= 100"
                         class="checkpoint"
                         :class="productTypes.find(type => type.type_id === item.product_type_id).progressBarClass"
                    >
                        <img src="~@/assets/images/bonus-system/done.svg" alt="">
                    </div>
                    <div v-else class="checkpoint checkpoint-text"
                         :class="[
                             productTypes.find(type => type.type_id === item.product_type_id).progressClass,
                             productTypes.find(type => type.type_id === item.product_type_id).textClass
                             ]"
                    >
                        <span>{{ item.amount_benzine }}</span>
                        <img v-if="item.product_type_id === 1" src="~@/assets/images/bonus-system/benzine-osago.svg" alt="">
                        <img v-else-if="item.product_type_id === 3" src="~@/assets/images/bonus-system/benzine-green-card.svg" alt="">
                        <img v-else-if="item.product_type_id === 5" src="~@/assets/images/bonus-system/benzine-tourism.svg" alt="">
                    </div>
                    <div class="position-absolute mt-1 checkpoint-text"
                         :class="productTypes.find(type => type.type_id === item.product_type_id).textClass"
                    >
                        <span>
                            {{ item.sum_sales }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mission-progress {
    .checkpoints {
        margin-bottom: unset !important;
        color: #FFAD3D;
        .checkpoint {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: table-cell;
            vertical-align: middle;
            img {
                width: 8px !important;
                height: 8px !important;
            }
        }
        .checkpoint-text {
            font-weight: 400;
            font-size: 14px;
        }
    }

    .progress {
        height: 4px;
    }

    .text-osago {
        color: #FFAD3D;
    }

    .text-tourism {
        color: #007BFF;
    }

    .text-green-card {
        color: #02A94E;
    }

    .progress-osago {
        background: #FFAD3D33;
    }

    .progress-tourism {
        background: #007BFF33;
    }

    .progress-green-card {
        background: #02A94E33;
    }

    .progress-bar-osago {
        background-color: #FFAD3D;
    }

    .progress-bar-tourism {
        background-color: #007BFF;
    }

    .progress-bar-green-card {
        background-color: #02A94E;
    }

    .mission-bonus-value {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 50%;
        height: 60%;

        span {
            color: #24282C;
            font-weight: 700;
            font-family: "Circe", sans-serif;
        }
    }
}

</style>
