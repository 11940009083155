<script>
import ProgressCircle from "@/views/bonusSystem/components/progress/ProgressCircle.vue";
import displaySettings from "@/mixin/displaySettings";
import {orderPurchases} from "@/api/statistics";
import {getLevelProgress} from "@/api/bonusSystem/level";

export default {
    name: "CarouselSlideLevel",
    components: {ProgressCircle},
    props: ['item'],
    mixins: [displaySettings],
    data() {
        return {
            sales: 0,
            showDescription: false,
            images: [
                require('@/assets/images/bonus-system/books-and-cup.svg'),
                require('@/assets/images/bonus-system/reward-badge-with-star-and-two-ribbons.svg'),
                require('@/assets/images/bonus-system/gold-trophy-in-air.svg'),
                require('@/assets/images/bonus-system/crown-slope.svg'),
            ],
            colorProgress: {
                level_1: {
                    circleColor: '#ED790480',
                    progressColor: '#ED7904'
                },
                level_2: {
                    circleColor: '#5CBDF380',
                    progressColor: '#5CBDF3'
                },
                level_3: {
                    circleColor: '#FFCF2680',
                    progressColor: '#FFCF26'
                },
                level_4: {
                    circleColor: '#9367C880',
                    progressColor: '#9367C8'
                },
            }
        }
    },
    computed: {
        salesPercentage() {
            if (this.item.max_sales) {
                return Math.round(this.sales / this.item.max_sales * 1000) / 10
            } else {
                return Math.round(this.sales / this.item.min_sales * 1000) / 10
            }
        },
        currentLevelId(){
            return this.$store.getters.USER_INFO.levelId;
        }
    },
    methods: {
        getImageLevel(levelId) {
            return this.images[levelId - 1];
        }
    },
    mounted() {
        if (this.item.id === this.currentLevelId) {
            getLevelProgress().then(response => {
                let sales = response.data.sumSales
                if (this.item.max_sales) {
                    this.sales = sales > this.item.max_sales ? this.item.max_sales : sales
                } else {
                    this.sales = sales > this.item.min_sales ? this.item.min_sales : sales
                }
            })
        }
    }
}
</script>

<template>
    <el-card :body-style="{padding: 0}" class="swiper-slide" shadow="never">
        <div v-if="item.id === currentLevelId" class="current-level pl-2 pr-2">
            <span>Ваш рівень</span>
        </div>
        <div v-if="isMobile" class="carousel-level" :class="[isMobile ? 'is-mobile' : '' , `level-${item.id}`]">
            <el-row justify="center">
                <el-col>
                    <div class="text-center">
                        <div class="title mt-3">
                            {{ item.name }}
                        </div>
                    </div>
                </el-col>
                <el-col>
                    <div class="d-flex justify-content-center" v-if="item.max_sales">
                        <progress-circle :value="salesPercentage"
                                         :circle-color="colorProgress[`level_${item.id}`].circleColor"
                                         :progress-color="colorProgress[`level_${item.id}`].progressColor"
                        ></progress-circle>
                    </div>
                    <div class="d-flex justify-content-center" v-else>
                        <div class="mb-2">
                            <img width="150" height="120" :src="getImageLevel(item.id)" alt="">
                        </div>
                    </div>
                    <div class="pt-2 text-center progress-value" v-if="item.max_sales">
                        {{ sales }} з {{ item.max_sales }}
                    </div>
                </el-col>
                <el-col>
                    <div class="d-flex justify-content-center pt-2">
                        <el-button
                            size="mini"
                            type="warning"
                            @click="showDescription = !showDescription"
                        >
                            Переглянути привілеї
                            <i class="el-icon-arrow-up" v-if="showDescription"></i>
                            <i class="el-icon-arrow-down" v-else></i>
                        </el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div v-else class="carousel-level" :class="`level-${item.id}`">
            <el-row type="flex" align="middle" justify="center" v-if="item.id !== 4">
                <el-col>
                    <div class="d-flex justify-content-center mt-4">
                        <progress-circle :value="salesPercentage"
                                         :circle-color="colorProgress[`level_${item.id}`].circleColor"
                                         :progress-color="colorProgress[`level_${item.id}`].progressColor"
                        ></progress-circle>
                    </div>
                    <div v-if="item.max_sales" class="pt-2 text-center progress-value">
                        {{ sales }} з {{ item.max_sales }}
                    </div>
                    <div v-else class="pt-2 text-center progress-value">
                        Більше {{ item.min_sales }}
                    </div>
                </el-col>
                <el-col>
                    <div class="text-center">
                        <div class="title mt-3">
                            {{ item.name }}
                        </div>
                        <div>
                            <img width="150" height="120" :src="getImageLevel(item.id)" alt="">
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row v-else class="mb-4">
                <el-col>
                    <div class="text-center">
                        <div class="title">
                            {{ item.name }}
                        </div>
                        <div class="mb-2">
                            <img width="150" height="120" :src="getImageLevel(item.id)" alt="">
                        </div>
                    </div>
                </el-col>
            </el-row>
            <div class="d-flex justify-content-center">
                <el-button
                    type="warning"
                    @click="showDescription = !showDescription"
                    size="mini"
                >
                    Переглянути привілеї
                    <i class="el-icon-arrow-up" v-if="showDescription"></i>
                    <i class="el-icon-arrow-down" v-else></i>
                </el-button>
            </div>
        </div>
        <div v-if="showDescription" class="description p-5">
            <div class="title mb-2">
                {{ item.name }}
            </div>
            <div class="text mb-2">
                Рівні бонусної системи користувачів Ukasko.connect залежать від об’єму продажів та
                надають
                можливість користувачам отримувати певні привілеї.
            </div>
            <div class="title pt-3">
                Привілеї доступні для даного рівня :
            </div>
<!--            //TODO move to backend -->
            <ul class="list" v-if="item.id === 1">
                <li>
                    Базові страхові продукти
                </li>
                <li>
                    Кредитний ліміт до -1500 грн
                </li>
                <li>
                    Базовий рівень бонусних винагород за виконання завдань
                </li>
                <li>
                    Максимальний розмір списання бонусами вартості поліса у розмірі 50 грн
                </li>
            </ul>
            <ul class="list" v-else-if="item.id === 2">
                <li>
                    Розширені страхові продукти
                </li>
                <li>
                    Кредитний ліміт до -5000 грн
                </li>
                <li>
                    Підвищений рівень винагород за виконання завдань
                </li>
                <li>
                    Додаткові бонусні завдання
                </li>
                <li>
<!--                    Максимальний розмір списання вартості полісу бонусами у розмірі 75 грн-->
                    Максимальний розмір списання вартості полісу бонусами у розмірі 50 грн
                </li>
            </ul>
            <ul class="list" v-else-if="item.id === 3">
                <li>
                    Весь спектр страхових продуктів
                </li>
                <li>
                    Кредитний ліміт до -10000 грн
                </li>
                <li>
                    Підвищений рівень винагород за виконання завдань
                </li>
                <li>
<!--                    Максимальний розмір списання вартості полісу бонусами у розмірі 100 грн-->
                    Максимальний розмір списання вартості полісу бонусами у розмірі 50 грн
                </li>
                <li>
                    Додаткові бонусні завдання
                </li>
                <li>
                    Персональні бонуси
                </li>
            </ul>
            <ul class="list" v-else-if="item.id === 4">
                <li>
                    Весь спектр страхових продуктів
                </li>
                <li>
                    Кредитний ліміт до -50000 грн
                </li>
                <li>
                    Додаткові бонусні завдання
                </li>
                <li>
                    Максимальний рівень винагород за виконання завдань
                </li>
                <li>
<!--                    Максимальний розмір списання вартості полісу бонусами у розмірі 125 грн-->
                    Максимальний розмір списання вартості полісу бонусами у розмірі 50 грн
                </li>
                <li>
                    Персональний менеджер
                </li>
                <li>
                    Персональні бонуси
                </li>
            </ul>
        </div>
    </el-card>
</template>

<style scoped>
.swiper-slide {
    width: 100%;
    box-shadow: 0px 4px 20px 0px #00000026;
}
.el-card {
    width: 100%;
    border: 0px;
    border-radius: 5px;
    .is-always-shadow {
        box-shadow: 0px 4px 20px 0px #00000026;
    }
}
.is-mobile {
    background-position: center!important;
    background-size: 328px, auto, cover!important;
}
.level-1 {
    background-color: rgba(237, 121, 4, 0.1);
    background-image: url("~@/assets/images/bonus-system/level-1.svg");
    background-position: right 3em bottom 0;
    background-size: contain;
    background-repeat: no-repeat;
}

.level-2 {
    background-color: rgba(92, 189, 243, 0.1);
    background-image: url("~@/assets/images/bonus-system/level-2.svg");
    background-position: right 3em bottom 0;
    background-size: contain;
    background-repeat: no-repeat;
}

.level-3 {
    background-color: rgba(255, 207, 38, 0.1);
    background-image: url("~@/assets/images/bonus-system/level-3.svg");
    background-position: right 3em bottom 0;
    background-size: contain;
    background-repeat: no-repeat;
}

.level-4 {
    background-color: rgba(147, 103, 200, 0.1);
    background-image: url("~@/assets/images/bonus-system/level-4.svg");
    background-position: right 3em bottom 0;
    background-size: contain;
    background-repeat: no-repeat;
}

.carousel-level {
    height: 256px;
    .title {
        font-weight: 700;
        font-family: "Circe", sans-serif;
        color: #ED7904;
        font-size: 35px;
    }

    .progress-value {
        font-weight: 400;
        font-family: "Circe", sans-serif;
        color: #24282C;
        font-size: 18px;
    }
}

.current-level {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FA9B13;
    border-radius: 5px 0px 5px 0px;

    span {
        color: white;
        font-weight: 700;
        font-family: "Circe", sans-serif;
        margin-left: 3px;
        margin-right: 3px;
        font-size: 1rem;
    }
}


.description {
    .title {
        font-weight: 700;
        font-family: "Circe", sans-serif;
        font-size: 18px;
    }

    .list {
        margin-top: 20px;
        padding-left: 20px;

        li {
            list-style-image: url('~@/assets/images/icons/check-green.svg');
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>
