<script>
import {getStatistics} from "@/api/bonusSystem/statistics";

export default {
    name: "Statistics",
    data() {
        return {
            period: 'today',
            periods: {
                'today': 'Сьогодні',
                'week': 'Тиждень',
                'month': 'Місяць',
                'year': 'Рік'
            },
            totalBonusesEarned: 0,
            totalSpendBonuses: 0,
            totalCompletedTasks: 0,
        }
    },
    methods: {
        getStatistics(query) {
            getStatistics(query).then(response => {
                this.totalCompletedTasks = response.data.totalCompletedTasks
                this.totalBonusesEarned = response.data.totalBonusesEarned
                this.totalSpendBonuses = response.data.totalSpendBonuses
            })
        },
        handlePeriod(period) {
            this.period = period
            let data = {};

            switch (period) {
                case 'today':
                    data = {
                        startDate: moment().format('YYYY-MM-DD'),
                        endDate: moment().format('YYYY-MM-DD')
                    }
                    break
                case 'week':
                    data = {
                        startDate: moment().subtract(7,'d').format('YYYY-MM-DD'),
                        endDate: moment().format('YYYY-MM-DD')
                    }
                    break
                case 'month':
                    data = {
                        startDate: moment().subtract(1,'M').format('YYYY-MM-DD'),
                        endDate: moment().format('YYYY-MM-DD')
                    }
                    break
                case 'year':
                    data = {
                        startDate: moment().subtract(1,'y').format('YYYY-MM-DD'),
                        endDate: moment().format('YYYY-MM-DD')
                    }
                    break
                default:
                    data = {startDate: new Date(), endDate: new Date()}
            }
            this.getStatistics(data)
        }
    },
    mounted() {
        this.getStatistics()
    }
}
</script>

<template>
<div class="statistics">
    <div class="d-flex justify-content-between pb-2">
        <div class="title">
            Статистика
        </div>
        <div class="period">
            <span>
                Період:
            </span>
            <el-dropdown trigger="click" @command="handlePeriod">
                <span class="item">
                    {{ periods[period] }} <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="key" :key="key" v-for="(item, key) in periods">{{ item }}</el-dropdown-item>
<!--                    <el-dropdown-item :command="week">Неділя</el-dropdown-item>-->
<!--                    <el-dropdown-item command="month">Місяць</el-dropdown-item>-->
<!--                    <el-dropdown-item command="year">Рік</el-dropdown-item>-->
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
    <el-card class="mb-3">
        <el-row type="flex">
            <el-col :span="18">
                <div>Виконано завдань</div>
            </el-col>
            <el-col :span="6">
                <div class="d-flex justify-content-end">
                    {{ totalCompletedTasks }}
                </div>
            </el-col>
        </el-row>
        <el-row type="flex">
            <el-col :span="18">
                <div>Всього зароблено бонусів</div>
            </el-col>
            <el-col :span="6">
                <div class="d-flex justify-content-end">
                    {{ totalBonusesEarned }}
                </div>
            </el-col>
        </el-row>
        <el-row type="flex">
            <el-col :span="18">
                <div>Зекономлено завдяки бонусам, грн</div>
            </el-col>
            <el-col :span="6">
                <div class="d-flex justify-content-end">
                    {{ totalSpendBonuses }}
                </div>
            </el-col>
        </el-row>
        <el-row type="flex">
            <el-col :span="18">
                <div class="position-relative" style="width: fit-content">
                    <div class="text-center soon">
                        <span class="label">Скоро</span>
                    </div>
                    <div class="pt-2">Реєстрації за моїм посиланням</div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="d-flex justify-content-end">
                    0
                </div>
            </el-col>
        </el-row>
        <el-row type="flex">
            <el-col :span="18">
                <div class="position-relative" style="width: fit-content">
                    <div class="text-center soon">
                        <span class="label">Скоро</span>
                    </div>
                    <div class="pt-2">Бонуси зароблені за реферальної програми</div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="d-flex justify-content-end">
                    0
                </div>
            </el-col>
        </el-row>
    </el-card>
</div>
</template>

<style scoped>
.statistics {
    .soon {
        position: absolute;
        right: -2rem;
        top: 0;
        border: 1px solid #EF4137;
        border-radius: 3px;
        width: 44px;
        height: 16px;
        display: inline-grid;
        .label {
            color: #EF4137;
            font-weight: 800;
            font-size: 10px;
            line-height: 15.74px;
        }
    }
    .el-row {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .title {
        font-weight: 700;
        font-family: "Circe", sans-serif;
        font-size: 20px;
    }
    .period {
        span {
            font-weight: 400;
            font-family: "Circe", sans-serif;
            font-size: 15px;
            color: #AEADAD;
        }
        .item {
            color: #24282C;
        }
    }
   .el-card {
        width: 100%;
        border-radius: 5px;

        .is-always-shadow {
            box-shadow: 0px 4px 20px 0px #00000026;
        }
    }
}

</style>
