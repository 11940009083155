<script>
import displaySettings from "@/mixin/displaySettings";

export default {
    name: "ProgressCircle",
    props: ['value', 'progressColor', 'circleColor'],
    mounted() {
        this.$refs.progress.$el.children[0].children[0].children[0].setAttribute('stroke', this.circleColor)
    },
    mixins: [displaySettings],
}
</script>

<template>
    <el-progress
            ref="progress"
            type="circle"
            :percentage="value"
            :color="progressColor"
            :width="isMobile ? 100 : 150"
    ></el-progress>
</template>

<style scoped>

</style>
